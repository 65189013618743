import ApplicationController from './application';

export default class extends ApplicationController {

  static targets = ['trigger', 'icon'];

  connect() {
    const { triggerTarget: trigger, iconTarget: icon } = this;
    if (!trigger || !icon) return;

    icon.classList.toggle('flip', true);

    this.toggle()
  }

  toggle() {
    const { triggerTarget: trigger, iconTarget: icon } = this;
    if (!trigger || !icon) return;

    const isOpen = trigger.classList.toggle('is-open');
    icon.classList.toggle('transition-all', true);
    icon.classList.toggle('flip', !isOpen);

    const parent = trigger.closest('.c-card');
    const children = parent.querySelectorAll('.c-card > .c-card__section');
    const dataset = trigger.dataset.exclude;
    const exclude = this.parseExcludeDataset(dataset);

    children.forEach((child, index) => {
      child.classList.add('md:block');
      child.classList.toggle('hidden', isOpen && !exclude.includes(index));
    });
  }

  parseExcludeDataset(dataset) {
    const isArray = dataset.includes('[') && dataset.includes(']');
    const newDataset = isArray ? this.prepare_data(dataset) : [parseInt(dataset)];
    return isArray ? [0, ...newDataset] : [0, newDataset[0]];
  }

  prepare_data(data) {
    return JSON.parse(data.replace(/'/g, '"'));
  }
}
