import ApplicationController from './application';

export default class extends ApplicationController {
  static targets = [
    'wrapper',
    'nav',
    'hamburger',
    'menu',
    'backdrop'
  ];

  connect() {
    const scrollWithPage = JSON.parse(this.element.dataset.navbarScrollWithPage);

    if (!this.areTargetsValid() || !scrollWithPage) return;

    const headerHeightThreshold = this.wrapperTarget.offsetHeight / 1.4;
    const isLogoOnly = JSON.parse(this.element.dataset.navbarOnlyLogo)
    const isActive = isLogoOnly ? false : this.hamburgerTarget.classList.contains('is-active');
    let scrollValue = window.scrollY;

    this.adjustNavbarOnScroll(scrollValue, headerHeightThreshold, isActive);

    const onScroll = () => {
      scrollValue = window.scrollY;

      this.adjustNavbarOnScroll(scrollValue, headerHeightThreshold, isActive);
    };

    document.addEventListener('scroll', onScroll);
  }

  toggle() {
    if (!this.areTargetsValid()) return;

    const headerHeightThreshold = this.wrapperTarget.offsetHeight / 1.4;
    const scrollValue = window.scrollY;
    const isClosed = this.menuTarget.classList.contains('hidden');

    this.hamburgerTarget.classList.toggle('is-active', isClosed);
    this.menuTarget.classList.toggle('hidden', !isClosed);
    this.backdropTarget.classList.toggle('hidden', !isClosed);

    this.addNavbarClasses(this.navTarget, scrollValue > headerHeightThreshold || isClosed);
    document.body.classList.toggle('overflow-hidden')
  }

  areTargetsValid() {
    if (JSON.parse(this.element.dataset.navbarOnlyLogo)) {
      return this.wrapperTarget && this.navTarget && this.backdropTarget;
    }
    return this.wrapperTarget && this.navTarget && this.hamburgerTarget && this.menuTarget && this.backdropTarget;
  }

  adjustNavbarOnScroll(scrollValue, headerHeightThreshold, isActive) {
    const hasScroll = scrollValue > headerHeightThreshold && document.querySelector('main').clientHeight > window.innerHeight
    this.addNavbarClasses(this.navTarget, hasScroll || isActive);
  }

  addNavbarClasses(nav, isActive) {
    nav.classList.toggle('fixed', isActive);
    nav.classList.toggle('shadow-md', isActive);
    nav.classList.toggle('border-b', isActive);
  }
}
