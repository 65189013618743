import ApplicationController from './application';

export default class extends ApplicationController {

  connect() {
    document.addEventListener('click', this.handleClick.bind(this));
  }

  handleClick(event) {
    const rsaOptions = [
      'i_was_born_in_south_africa',
      'at_some_point_i_was_registered_as_a_south_african_citizen',
    ];
    const notRsa = 'i_was_not_born_in_south_africa_and_never_registered_as_a_south_african_permanent_resident';

    // Determine the user's selection
    if (rsaOptions.includes(event.target.id)) {
      this.updateCardVisibility(0); // Show RSA-specific card
    } else if (event.target.id === notRsa) {
      this.updateCardVisibility(1); // Show Non-RSA card
    }
  }

  updateCardVisibility(visibleIndex) {
    // Cache DOM elements
    const cardWrapper = document.querySelector('.rsa-card');
    const cardList = cardWrapper?.querySelectorAll('.c-card') || [];
    const cardActions = document.querySelectorAll('.rsa-action button');

    // Ensure elements exist
    if (!cardWrapper || cardList.length < 2 || cardActions.length < 2) return;

    // Unhide the card wrapper
    cardWrapper.classList.remove('hidden');

    // Show the appropriate card and actions by index
    cardList.forEach((card, index) => {
      card.classList.toggle('hidden', index !== visibleIndex);
    });
    cardActions.forEach((action, index) => {
      action.classList.toggle('hidden', index !== visibleIndex);
    });
  }
}
